import React from 'react';
import * as styles from './ReviewCard.module.scss';
import Icon from '../Icon/Icon';
import ReviewIcons from '../ReviewIcons/ReviewIcons';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

const ReviewCard = ({ rating, title, content, name, date, reviewId }) => {
  return (
    <div className={styles.root} id={`review-${reviewId}`}>
      <section className="details">
        <Icon symbol="cat" />
        <div className="details-inner">
          <ReviewIcons rating={rating} gap={'5px'} />
          <p>{name}</p>
          <p className="date">{dayjs(date).format('Do MMM YYYY')}</p>
        </div>
      </section>
      <section className="review">
        <p className="title">{title}</p>
        <p className="content">{content}</p>
      </section>
    </div>
  );
};

export default ReviewCard;
